import './App.css';
import { CountdownCircleTimer } from 'react-countdown-circle-timer';
import { useState, useEffect} from 'react';
import moment from 'moment';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@material/web/progress/linear-progress.js';
import '@material/web/button/filled-tonal-button.js';
// import { createClient } from '@supabase/supabase-js'

function App() {
  const [isActive,setIsActive] = useState(false)
  const [isplaying, setIsplaying] = useState(false)
  const [squOrRest,setSquOrRest] = useState('squeeze')
  const [color,setColor] = useState('#0F7173')
  const min = 1;
  const [loopTime,setLoopTime] = useState(0)
  const [timeLeft,setTimeLeft] = useState(min*60)
  const [restart, setRestart] = useState(0)
  const [lastFinishedSetDate,setlastFinishedSetDate] = useState(0)
  const [finishedSet,setFinishedSet] = useState()

  //Read finishedSet and initlastFinishedSetDate in the local storage at the start.
  useEffect(() => {
    if(localStorage.getItem('finishedSet')){
      let initFinishedSet = JSON.parse(localStorage.getItem('finishedSet'))
      setFinishedSet(initFinishedSet)
      console.log(`initFinishedSet: ${initFinishedSet}`)
    }
    if(localStorage.getItem('lastFinishedSetDate')){
      let initlastFinishedSetDate = JSON.parse(localStorage.getItem('lastFinishedSetDate'))
      setlastFinishedSetDate(initlastFinishedSetDate)
      console.log(`initlastFinishedSetDate: ${initlastFinishedSetDate}`)
      //if initlastFinishedSetDate is not today, reset the finishedSets to zero
      if(initlastFinishedSetDate !== moment().dayOfYear()){
        setFinishedSet(0)
      }
    }else{
      setFinishedSet(0)
    }
  },[])


  //The count down implementation
  useEffect(() => {
    let Timeid
    if(isActive){
      if(timeLeft !== 0){
        setIsplaying(true)
        Timeid = setInterval(() => {
          setTimeLeft((prevTimeLeft) => prevTimeLeft = 0 ? 0 : prevTimeLeft - 1)
        }, 1000);
      }else{
        clearInterval(Timeid)
        //When time is up ,Count the finished sets and store the currentFinishedSetDate
        setFinishedSet((prevFinishSet) => prevFinishSet + 1)
        setlastFinishedSetDate(moment().dayOfYear())
      }
    }else{
      clearInterval(Timeid)
      setIsplaying(false)
    }
    return () => clearInterval(Timeid)
  },[timeLeft,isActive])


  //Store finishSet and finishedHour to the localStorage
  useEffect(() => { 
    localStorage.setItem('finishedSet',JSON.stringify(finishedSet))
    localStorage.setItem('lastFinishedSetDate',JSON.stringify(lastFinishedSetDate))
  },[finishedSet,lastFinishedSetDate])

  //Start or pause the timer 
  function handleisActiveChange() {
    setIsActive(!isActive)
    setIsplaying(!isActive)
    // console.log(isActive)
  }

  //Reset the timer
  function handelReset() {
    setLoopTime(0)
    setTimeLeft(min*60)
    setSquOrRest('squeeze')
    setColor('#0F7173')
    setIsActive(false)
    setRestart((prev) => prev + 1)
  }
  



  return (
    <div className="App">
        <CountdownCircleTimer
          key={restart}
          isPlaying={isplaying}
          duration={3}
          colors={color}
          onComplete={()=>{
            setLoopTime((prevLoopTime) => {
              return prevLoopTime + 1
            })
            if (loopTime < (((min*60)/3)-1)){
              if(squOrRest === 'squeeze'){
                setSquOrRest('rest')
                setColor('#FFBA08')
              }if(squOrRest === 'rest'){
                setSquOrRest('squeeze')
                setColor('#0F7173')
              }
              return{shouldRepeat: true, delay: 0}
            }else{
              setIsplaying(false)

            }
          }}
        >
    {({ remainingTime }) => {
      if(timeLeft){
        return `${remainingTime} seconds ${squOrRest}`
      }else{
        return 'Finish'
      }
    }}
  </CountdownCircleTimer>
  <br></br>
  <div className='start-pause'>
    <md-filled-tonal-button onClick={handleisActiveChange}>{isActive ? 'Pause':'Start'}</md-filled-tonal-button>
  </div>
  <br></br>
  <div>
    <md-filled-tonal-button onClick={handelReset}>Reset Time</md-filled-tonal-button>
    <md-filled-tonal-button onClick={()=> setFinishedSet(0)}>Reset Set</md-filled-tonal-button>
  </div>
  <div>Remaining Time: {timeLeft}s</div>
  <md-linear-progress className='md-bar' value={(timeLeft/60)}></md-linear-progress>
  <div>Fisnished Sets: {finishedSet}</div>
    </div>
  );
}

export default App;
